import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Footer from "../components/footer"
import Contact from "../components/contact"
import "../components/singlepage.scss"

const ContactPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        lang="it"
        title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
        description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
      />
      <div className="fix-content-width">
        <NavBar />
      </div>
      <div style={{ height: "25vh" }}></div>
      <Contact />
    </Layout>
  )
}

export default ContactPage
